import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Auth from "./shade/layouts/auth";
import "./index.scss";
import "./App.css";
import { CLILoginSuccess } from "./views/Authentication/CLILoginSuccess";
import Project from "./views/Project/Project";
// import awesome font
import "font-awesome/css/font-awesome.min.css";
import TestInterface from "./views/TestInterface/TestInterface";
import GenezioSpinner from "./components/Utilities/Spinner/GenezioSpinner";
import Profile from "./views/Profile";
import Billing from "./views/Billing";
import SubscriptionCallback from "./views/SubscriptionCallback";
import EnvironmentVariables from "./views/EnvironmentVariables";
import UpstashRedirect from "./views/Integrations/UpstashRedirect";
import UpstashDetails from "./views/Integrations/UpstashDetails";
import SentryDetails from "./views/Integrations/SentryDetails";
import NeonDetails from "./views/Integrations/NeonDetails";
import NeonRedirect from "./views/Integrations/NeonRedirect";
import QstashDetails from "./views/Integrations/QstashDetails";
import ImportProjectFlow from "./views/NewProjectFlow/ImportProjectFlow";

import ProjectDeployDetails from "./views/NewProject/ProjectDeployDetails";
import Congrats from "./views/NewProject/Congrats";

import Collaboration from "./views/Collaboration/Collaboration";
import CollaborationInvitation from "./views/Collaboration/CollaborationInvitation";

import * as Sentry from "@sentry/react";
import EmailServiceIntegration from "./views/Integrations/EmailServiceIntegration";

import App from "../src/shade/layouts/App";
import Dashboard from "./views/Dashboard";
import AuthLogin from "./views/Authentication/Login";
import AuthSignup from "./views/Authentication/Signup";
import GithubRedirect from "./views/Authentication/GithubRedirect";
import RedirectLoginCLI from "./views/Authentication/RedirectLoginCLI";
import AuthCLILogin from "./views/Authentication/AuthCLILogin";
import Lambda from "./views/Lambda";
import AccessTokens from "./views/Settings/AccessTokens";
import ProjectAuthentication from "./views/ProjectAuthentication/ProjectAuthentication";
import Databases from "./views/Databases";
import Backend from "./views/Backend/Backend";
import Frontend from "./views/Frontend/Frontend";
import Logs from "./views/Logs/Logs";
import Integrations from "./views/Integrations";
import Database from "./views/Database/Database";
import ExpressGettingStarted from "./views/ExpressGettingStarted";
import NextJsGettingStarted from "./views/NextJsGettingStarted";
import FaasGettingStarted from "./views/FaasGettingStarted";
import { EditCode } from "./views/EditCode";
import DeployRepoOnGenezio from "./views/DeployRepoOnGenezio";
import Start from "./shade/layouts/Start";
import TypeSafeGettingStarted from "./views/TypeSafeGettingStarted";
import CronGettingStarted from "./views/CronGettingStarted";
import WebScraperGettingStarted from "./views/WebScraperGettingStarted";
import ChatGPTGettingStarted from "./views/ChatGptGettingStarted";
import HtmlGettingStarted from "./views/HtmlGettingStarted";
import ReactGettingStarted from "./views/ReactGettingStarted";
import AngularGettingStarted from "./views/AngularGettingStarted";
import NewProjectFlow from "./views/NewProjectFlow/NewProjectFlow";
import ExpressFullStackReact from "./views/ExpressFullStackReact";
import VueGettingStarted from "./views/VueGettingStarted";
import FastifyGettingStarted from "./views/FastifyGettingStarted";
import WhatsAppGettingStarted from "./views/WhatsAppGettingStarted";
import SvelteGettingStarted from "./views/SvelteGettingStarted";
import NuxtGettingStarted from "./views/NuxtGettingStarted";

//Form
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.Fragment>
    <BrowserRouter>
      <React.Suspense
        fallback={
          <div className="loader-img">
            <GenezioSpinner />
          </div>
        }
      >
        <Routes>
          <Route path={`/auth`} element={<Auth />}>
            <Route path={`/auth/github-redirect`} element={<GithubRedirect />} />
            <Route path={`/auth/login`} element={<AuthLogin />} />
            <Route path={`/auth/signup`} element={<AuthSignup />} />
          </Route>

          <Route path={`/clilogin`} element={<AuthCLILogin />} />

          <Route path={`/start`} element={<Start />}>
            <Route path={`/start/deploy`} element={<DeployRepoOnGenezio />} />
          </Route>

          <Route path={`/`} element={<App />}>
            <Route path={`/typesafe-getting-started`} element={<TypeSafeGettingStarted />} />
            <Route path={`/express-getting-started`} element={<ExpressGettingStarted />} />
            <Route path={`/html-getting-started`} element={<HtmlGettingStarted />} />
            <Route path={`/angular-getting-started`} element={<AngularGettingStarted />} />
            <Route path={`/react-getting-started`} element={<ReactGettingStarted />} />
            <Route path={`/faas-getting-started`} element={<FaasGettingStarted />} />
            <Route path={`/nextjs-getting-started`} element={<NextJsGettingStarted />} />
            <Route path={`/cron-getting-started`} element={<CronGettingStarted />} />
            <Route path={`/web-scraper-getting-started`} element={<WebScraperGettingStarted />} />
            <Route path={`/chatgpt-getting-started`} element={<ChatGPTGettingStarted />} />
            <Route path={`/express-fullstack-getting-started`} element={<ExpressFullStackReact />} />
            <Route path={`/vue-getting-started`} element={<VueGettingStarted />} />
            <Route path={`/fastify-getting-started`} element={<FastifyGettingStarted />} />
            <Route path={`/whatsapp-getting-started`} element={<WhatsAppGettingStarted />} />
            <Route path={`/svelte-getting-started`} element={<SvelteGettingStarted />} />
            <Route path={`/nuxt-getting-started`} element={<NuxtGettingStarted />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path={`/dashboard`} element={<Dashboard />} />
            <Route path={`/new-project`} element={<Dashboard />} />
            {/* TODO: add a param to know if is github import or template project */}
            {/* <Route path={`/new-project/import-from-github/`} element={<NewProjectFlow />} /> */}
            <Route path={`/import/:id`} element={<ImportProjectFlow />} />
            <Route path={`/import`} element={<ImportProjectFlow />} />
            {/* /fork */}
            <Route path={`/fork`} element={<NewProjectFlow />} />
            <Route path={`/dashboard/:framework`} element={<Dashboard />} />
            {/* DATABASES START */}
            <Route path={"/databases"} element={<Databases />} />
            <Route path={"/project/:projectId/:envId/backend"} element={<Backend />} />
            <Route path={"/project/:projectId/:envId/frontend"} element={<Frontend />} />
            <Route path={"/project/:projectId/:envId/integrations"} element={<Integrations />} />
            <Route path={"/project/:projectId/:envId/logs"} element={<Logs />} />
            <Route path={"/edit-code/:projectId/:envId/"} element={<EditCode />} />
            {/* DATABASES END */}
            <Route path={`/settings/tokens`} element={<AccessTokens />} />
            <Route path={`/test-interface/:projectId`} element={<TestInterface />} />
            <Route path={`/test-interface/:projectId/:envId`} element={<TestInterface />} />
            <Route path={`/profile`} element={<Profile />} />
            <Route path={`/billing`} element={<Billing />} />
            <Route path={`/subscription-callback`} element={<SubscriptionCallback />} />
            <Route path={"/project/:projectId/:envId/environment-variables"} element={<EnvironmentVariables />} />
            {/* <Route path={"/project/:projectId/all-integrations"} element={<Integrations />} /> */}
            <Route path={"/integrations/upstash/callback"} element={<UpstashRedirect />} />
            <Route path={"/integrations/neon/callback"} element={<NeonRedirect />} />
            <Route path={"/integrations/:projectId/:envId/neon-postgres"} element={<NeonDetails />} />
            <Route path={"/integrations/:projectId/:envId/upstash-qstash"} element={<QstashDetails />} />
            <Route path={"/integrations/:projectId/:envId/upstash-redis"} element={<UpstashDetails />} />
            <Route path={"/integrations/:projectId/:envId/email-service"} element={<EmailServiceIntegration />} />
            <Route path={"/integrations/:projectId/:envId/database"} element={<Database />} />
            <Route path={"/integrations/:projectId/:envId/sentry"} element={<SentryDetails />} />
            <Route path={`/project/:projectId/:envId/logs/:classId`} element={<Lambda />} />
            <Route path={`/project/:projectId/:envId`} element={<Project />} />
            <Route path={`/project/:projectId/:envId/:activeTab`} element={<Project />} />
            {/* Hide the New Project feature for now, because we want to focus on teaching the user how to create a project using the CLI for now */}
            {/* <Route path={`/new-project`} element={<AddProject />} /> */}
            <Route
              path={`/new-project/details/:projectName/:region/:backendName/:backendOwner/:frontendName/:frontendOwner`}
              element={<ProjectDeployDetails />}
            />
            <Route
              path={`/new-project/details/:projectName/:region/:backendName/:backendOwner`}
              element={<ProjectDeployDetails />}
            />
            <Route
              path={`/new-project/congrats/:projectName/:region/:backendName/:backendOwner/:frontendName/:frontendOwner`}
              element={<Congrats />}
            />
            <Route
              path={`/new-project/congrats/:projectName/:region/:backendName/:backendOwner`}
              element={<Congrats />}
            />
            <Route path={`/collaboration/:projectId/:envId/`} element={<Collaboration />} />;
            <Route path={`/collaboration/invitation/:invitationId`} element={<CollaborationInvitation />} />;
            <Route path={`/authentication/:projectId/:envId/`} element={<ProjectAuthentication />} />;
          </Route>

          <Route path={`/cli/login`} element={<RedirectLoginCLI />} />
          <Route path={`/cli/login/success`} element={<CLILoginSuccess />} />

          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
