import React, { useState } from "react";
import { Col, Row, Card, Button, InputGroup, Spinner, Alert } from "react-bootstrap";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { deleteFrontendByCustomDomain, requestCustomDomain, restartFrontendDomainCron } from "../../network/ApiAxios";
import { Input } from "@material-ui/core";

export default function FrontendElement(props: {
  userInfo: any;
  refreshFrontends: any;
  currentCollaboratorDetails: any;
  frontend: any;
  overview: any;
}) {
  const { frontend, userInfo, refreshFrontends, currentCollaboratorDetails, overview } = props;
  const { projectId, envId } = useParams<{ projectId?: string; envId: string }>();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [domain, setDomain] = useState<string>(frontend.customDomain ?? "");
  const [activeSaveBtn, setActiveSaveBtn] = useState<Boolean>(false);

  const [copyBtnNumber, setCopyBtnNumber] = useState<number>(-1);

  const [isCheckingDomain, setIsCheckingDomain] = useState<boolean>(false);

  const [inputDomainError, setInputDomainError] = useState<string>("");

  const navigate = useNavigate();

  React.useEffect(() => {
    // if (resp.data.user.subscriptionPlan === "Hobby") {
    //   setInputDomainError("You need to upgrade to a paid plan to use this feature.");
    // }
  }, [userInfo]);

  React.useEffect(() => {
    setDomain(frontend.customDomain ?? "");
  }, [frontend]);

  async function handleDeleteDomain() {
    // adtive alert
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`Are you sure you want to delete domain ${frontend.customDomain}?`)
    ) {
      // delete domain
      setIsDeleting(true);
      const res: any = await deleteFrontendByCustomDomain(frontend.genezioDomain);
      setIsDeleting(false);
      if (res.data && res.data.status === "ok") {
        refreshFrontends();
        setActiveSaveBtn(false);
        setDomain("");
      } else {
        alert("Something went wrong! Please try again.");
      }
    }
  }

  async function handleSaveDomain() {
    if (domain === "") {
      return;
    }
    if (!activeSaveBtn) {
      return;
    }

    if (!domain.match(/^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/)) {
      setInputDomainError("Domain is not valid");
      return;
    }

    // if (userInfo.subscriptionPlan === "Hobby") {
    //   window.location.href = "/billing?plans_modal=true";
    //   return;
    // }
    setIsSaving(true);
    // save domain
    const res: any = await requestCustomDomain(frontend.genezioDomain, domain);
    setIsSaving(false);
    if (res.data && res.data.status === "ok") {
      refreshFrontends();
      setActiveSaveBtn(false);
    } else {
      setInputDomainError("Domain is not valid");
    }
  }

  function renderStatus(): any {
    switch (frontend.domainStatus) {
      case "CUSTOM_DOMAIN_VALIDATED":
        return <span className="badge bg-success">Active</span>;
      case "CUSTOM_DOMAIN_FAILED":
        return <span className="badge bg-danger">Checks failed</span>;
      case "CERTIFICATE_FAILED":
        return <span className="badge bg-danger">Failed</span>;
      default:
        return <span className="badge bg-warning">Check in Progress</span>;
    }
  }

  async function checkAgainDomain(e: any) {
    e.preventDefault();
    setIsCheckingDomain(true);
    if (frontend.domainStatus === "CUSTOM_DOMAIN_FAILED") {
      await restartFrontendDomainCron(frontend.genezioDomain);
    } else if (frontend.domainStatus === "CERTIFICATE_FAILED") {
      await requestCustomDomain(frontend.genezioDomain, frontend.customDomain);
    }
    setIsCheckingDomain(false);
    refreshFrontends();
  }

  async function handleCopyToClipboard(str: string, copyBtnNumberLocal: number) {
    setCopyBtnNumber(copyBtnNumberLocal);
    await navigator.clipboard.writeText(str);
    setTimeout(() => {
      setCopyBtnNumber(-1);
    }, 500);
  }

  function renderCertificateFailedAlert() {
    if (frontend.domainStatus === "CERTIFICATE_FAILED") {
      switch (frontend.failureReason) {
        case "CAA_ERROR":
          return (
            <Row>
              <Col sm={8} className="col-8">
                <Alert variant="danger">
                  <p className="mb-0">
                    <b>CAA error</b> - The CAA record for your domain is preventing us from issuing a certificate.
                    Please add a CAA record for your domain with the values below.{" "}
                    <a
                      href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "troubleshooting#dns-misconfiguration-1"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>genezio troubleshooting guide</b>{" "}
                    </a>
                  </p>
                </Alert>
              </Col>
            </Row>
          );
        case "DOMAIN_NOT_ALLOWED":
          return (
            <Row>
              <Col sm={8} className="col-8">
                <Alert variant="danger">
                  <p className="mb-0">
                    <b>Domain Not Allowed</b> - We were unable to issue a certificate for your domain. Please check your
                    DNS records and try again. If you believe that the result is a false positive, notify the
                    organization that is reporting the domain. VirusTotal is an aggregate of several antivirus and URL
                    scanners and cannot remove your domain from a blacklist itself. After you correct the problem and
                    the VirusTotal registry has been updated, press "Check Again". If the problem persists, contact us.{" "}
                    <a
                      href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "troubleshooting#dns-misconfiguration-1"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>genezio troubleshooting guide</b>{" "}
                    </a>
                  </p>
                </Alert>
              </Col>
            </Row>
          );
        case "INVALID_PUBLIC_DOMAIN":
          return (
            <Row>
              <Col sm={8} className="col-8">
                <Alert variant="danger">
                  <p className="mb-0">
                    <b>Invalid Domain</b> - The domain name is not valid. Typically, this is because your domain name is
                    not a valid top-level domain. Try again after correcting any spelling errors or typos that were in
                    the failed request, and ensure that the domain name is valid for valid top-level domains.{" "}
                    <a
                      href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "troubleshooting#dns-misconfiguration-1"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>genezio troubleshooting guide</b>{" "}
                    </a>
                  </p>
                </Alert>
              </Col>
            </Row>
          );
        default:
          return (
            <Row>
              <Col sm={8} className="col-8">
                <Alert variant="danger">
                  <p className="mb-0">
                    <b>{frontend.failureReason}</b> - We were unable to issue a certificate for your domain. Please
                    check your DNS records and try again.{" "}
                    <a
                      href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "troubleshooting#dns-misconfiguration-1"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <b>genezio troubleshooting guide</b>
                    </a>
                  </p>
                </Alert>
              </Col>
            </Row>
          );
      }
    } else {
      return null;
    }
  }

  return (
    <Card.Body className="pt-0">
      <div>
        <div className="d-flex justify-content-between align-items-center">
          {frontend?.genezioDomain && (
            <Row className="pb-2 pt-1">
              <Col sm={12} className="col-12">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <h6 className="mb-0">Current Domain:</h6>
                  </div>
                  <div>
                    <p className="mb-0">
                      {!(frontend.domainStatus === "CUSTOM_DOMAIN_VALIDATED") ? (
                        <a
                          className="text-decoration-underline"
                          href={"https://" + frontend.fullDomain}
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://{frontend.fullDomain}
                        </a>
                      ) : (
                        <a
                          className="text-decoration-underline"
                          href={"https://" + frontend.customDomain}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {frontend.customDomain}
                        </a>
                      )}
                    </p>
                  </div>
                </div>
              </Col>
              {inputDomainError && <span className="text-danger me-2">{inputDomainError}</span>}
            </Row>
          )}

          {overview && (
            <Button
              onClick={() => navigate(`/project/${projectId}/${envId}/frontend/`)}
              className="py-1 px-3 bg-transparent"
              variant="outline-primary"
            >
              Manage domains
            </Button>
          )}
        </div>

        {frontend.customDomain !== undefined && !overview && (
          <Row className="pb-2">
            <Col sm={12} className="col-12">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <h6 className="mb-0">Custom Domain:</h6>
                </div>
                <div className="d-md-inline-flex">
                  <InputGroup size="sm">
                    <Input
                      className="form-control custom-domain-input-box mb-sm-2 mb-0"
                      placeholder="Your domain"
                      style={{ width: "250px" }}
                      // disabled={userInfo.subscriptionPlan === "Hobby"}
                      value={domain}
                      onChange={(e) => {
                        setDomain(e.target.value);
                        // check if the domain is valid with regex
                        setInputDomainError("");

                        if (e.target.value !== frontend.customDomain) {
                          setActiveSaveBtn(true);
                        } else {
                          setActiveSaveBtn(false);
                        }
                      }}
                    />
                  </InputGroup>

                  <Button
                    variant="outline-success"
                    size="sm"
                    className="ms-2 px-4"
                    style={{ display: "flex" }}
                    disabled={
                      !activeSaveBtn || isSaving || isDeleting || currentCollaboratorDetails.role === "collaborator"
                        ? true
                        : false
                    }
                    /*   ||
                    userInfo.subscriptionPlan === "Hobby"
                  */
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveDomain();
                    }}
                  >
                    Save {isSaving && <Spinner animation="border" className="ms-2" size="sm" />}
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="ms-2 px-4"
                    style={{ display: "flex" }}
                    disabled={
                      isDeleting ||
                      isSaving ||
                      !frontend.customDomain ||
                      currentCollaboratorDetails.role === "collaborator"
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteDomain();
                    }}
                  >
                    Remove {isDeleting && <Spinner animation="border" className="ms-2" size="sm" />}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {frontend.customDomain && (
          <Row className="pb-2">
            <Col sm={12} className="col-12">
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <h6 className="mb-0">Status:</h6>
                </div>
                <div>
                  <p className="mb-0">{renderStatus()}</p>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {frontend.customDomain && (
          <React.Fragment>
            <Row className="pb-2 pt-2">
              <Col sm={12}>
                <p>
                  Custom domains allow you to serve your site from a domain other than {frontend.fullDomain}
                  . <br />
                  We have to verify your domain ownership before you can use it. Add the following CNAME records to your
                  DNS provider.
                  <a
                    href={process.env.REACT_APP_GENEZIO_DOCUMENTATION + "frontend-deployment/quickstart"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b> Learn more</b>
                  </a>{" "}
                  <br />
                  This process can take <b>up to 24 hours to complete</b> after you add the records to your DNS
                  provider.
                </p>
              </Col>
            </Row>
            <Row className="pb-2">
              <Col sm={12} className="col-12">
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="px-4"
                  disabled={isCheckingDomain || currentCollaboratorDetails.role === "collaborator" ? true : false}
                  onClick={(e) => {
                    checkAgainDomain(e);
                  }}
                >
                  Check Again {isCheckingDomain && <Spinner animation="border" size="sm" />}
                </Button>
              </Col>
            </Row>
            {renderCertificateFailedAlert()}
            <Row className="pb-2">
              <Col sm={12} className="col-12">
                <table className="table table-responsive text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th className="text-left" style={{ width: "15%" }}>
                        <span className="tabletitle">Domain Name</span>
                      </th>
                      <th className="text-center" style={{ width: "10%" }}>
                        <span className="tabletitle">Type</span>
                      </th>
                      <th className="text-left" style={{ width: "40%" }}>
                        <span className="tabletitle">Name</span>
                      </th>
                      <th className="text-left" style={{ width: "40%" }}>
                        <span className="tabletitle">Value</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {frontend.domainStatus === "CERTIFICATE_FAILED" && frontend.failureReason === "CAA_ERROR" && (
                      <tr className="bg-warning">
                        <td className="text-left">{frontend.customDomain}</td>
                        <td className="text-center">CAA</td>
                        <td className="text-left">
                          {frontend.customDomain}{" "}
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCopyToClipboard(frontend.customDomain, 5);
                            }}
                          >
                            {copyBtnNumber === 5 ? "Copied" : "Copy"}
                          </Button>
                        </td>
                        <td className="text-left">
                          {"amazon.com"}{" "}
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCopyToClipboard("amazon.com", 6);
                            }}
                          >
                            {copyBtnNumber === 6 ? "Copied" : "Copy"}
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="text-left">{frontend.customDomain}</td>
                      <td className="text-center">CNAME</td>
                      <td className="text-left">
                        {frontend.cnameName}{" "}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCopyToClipboard(frontend.cnameName, 1);
                          }}
                        >
                          {copyBtnNumber === 1 ? "Copied" : "Copy"}
                        </Button>
                      </td>
                      <td className="text-left">
                        {frontend.cnameValue}{" "}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCopyToClipboard(frontend.cnameValue, 2);
                          }}
                        >
                          {copyBtnNumber === 2 ? "Copied" : "Copy"}
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left">{frontend.customDomain}</td>
                      <td className="text-center">CNAME</td>
                      <td className="text-left">
                        {frontend.customDomain}{" "}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCopyToClipboard(frontend.customDomain, 3);
                          }}
                        >
                          {copyBtnNumber === 3 ? "Copied" : "Copy"}
                        </Button>
                      </td>
                      <td className="text-left">
                        {frontend.distributionDomain}{" "}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="ms-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleCopyToClipboard(frontend.distributionDomain, 4);
                          }}
                        >
                          {copyBtnNumber === 4 ? "Copied" : "Copy"}
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    </Card.Body>
  );
}
