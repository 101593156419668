export function getUTMSignupSource(urlParams: URLSearchParams): string {
  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");

  if (!utmSource && !utmMedium && !utmCampaign) {
    return "";
  }

  return [utmSource, utmMedium, utmCampaign].map((e) => (Boolean(e) ? e : "*")).join("/");
}

export const capitalizeFirstLetter = (word: string) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
};

export function copyToClipboard(
  textToCopy: string,
  btn: string,
  buttonCopyState: string,
  setButtonCopyState: (value: string) => void,
) {
  if (buttonCopyState) {
    return;
  }

  setButtonCopyState(btn);
  navigator.clipboard.writeText(textToCopy);
  setTimeout(() => {
    setButtonCopyState("");
  }, 500);
}

export const defaultReservedEnvNames = ["NODE_OPTIONS"];

export const DEFAULT_STAGE_NAME = "prod";

export const nonBinaryFileExtensions = [
  "txt",
  "json",
  "yaml",
  "yml",
  "js",
  "ts",
  "jsx",
  "tsx",
  "md",
  "html",
  "css",
  "scss",
  "less",
  "graphql",
  "gql",
  "xml",
  "csv",
  "log",
  "env",
  "sh",
  "bash",
  "zsh",
  "fish",
  "ps1",
  "bat",
  "cmd",
  "py",
  "rb",
  "php",
  "java",
  "go",
  "pl",
  "perl",
  "r",
  "lua",
  "swift",
  "kt",
  "kts",
  "kotlin",
  "scala",
  "groovy",
  "rust",
  "rs",
  "cs",
  "csharp",
  "c",
  "h",
  "cpp",
  "hpp",
  "hxx",
  "h++",
  "cc",
  "cxx",
  "c++",
  "m",
  "mm",
  "makefile",
  "mk",
  "make",
  "dockerfile",
  "gradle",
  "properties",
  "ini",
  "conf",
  "toml",
  "lock",
  "gitignore",
  "npmrc",
  "yarnrc",
  "editorconfig",
  "gitattributes",
  "babelrc",
  "eslintrc",
  "prettierrc",
  "stylelintrc",
  "eslintignore",
  "prettierignore",
  "stylelintignore",
  "gitignore",
  "dockerignore",
  "npmignore",
  "yarnignore",
  "jshintrc",
  "flowconfig",
];
